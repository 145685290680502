import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-decline-di-modal',
  templateUrl: './decline-di-modal.component.html',
  styleUrls: ['./decline-di-modal.component.css'],
})
export class DeclineDiModalComponent implements OnInit {
  public form!: FormGroup;
  public loading: boolean = false;
  public msg_error: string | boolean = false;
  public maxDescriptionLength: number = 30;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DeclineDiModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public ngOnInit(): void {
    this.ngBuildForm();
    this.ngInit();
  }

  public ngBuildForm(): void {
    this.form = this.fb.group({
      description: ['', [Validators.required, Validators.maxLength(this.maxDescriptionLength)]],
    });
  }

  public onSubmit(e: any): void {
    e.preventDefault();

    if (this.form.valid) {
      this.loading = true;
      this.dialogRef.close({ description: this.description.value });
      this.loading = false;
    } else {
      this.form.markAllAsTouched();
    }
  }

  public ngClose(): void {
    this.dialogRef.close();
  }

  private ngInit(): void {}

  get description() {
    return this.form.get('description')!;
  }
}
