import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  public onBack: boolean = false;
  constructor() { }

  public ngShowRoutingBack(): void {
    this.onBack = true;
  }

  public ngHiddenRoutingBack(): void {
    this.onBack = false;
  }
}
