import { IUser } from '../../interfaces';
import { MBase } from './base.model';

export class MUser extends MBase implements IUser {
  public name: string;
  public surname: string;
  public username: string;
  public email: string;
  public password: string;
  public nif: string;
  public enterprise_id: number;
  public enterprise_name: string;
  public role_id: number;
  public role_name: string;
  public telephone: string;
  public address: string;
  public cp_id: number;
  public cp_code: string;
  public cp_province: string;
  public cp_town: string;
  public is_checked: boolean;
  public permissions: string[];

  constructor(user: IUser) {
    super(
      user.id,
      user.is_delete,
      user.is_active,
      user.created_at,
      user.updated_at,
      user.user_create_id,
      user.user_created_name
    );

    this.name = user.name;
    this.surname = user.surname;
    this.username = user.username;
    this.nif = user.nif;

    this.email = user.email;
    this.password = user.password ? user.password : '';

    this.enterprise_id = user.enterprise_id;
    this.enterprise_name = user.enterprise_name;

    this.role_id = user.role_id;
    this.role_name = user.role_name;

    this.telephone = user.telephone;
    this.address = user.address;

    this.cp_id = user.cp_id;
    this.cp_code = user.cp_code;
    this.cp_province = user.cp_province;
    this.cp_town = user.cp_town;

    this.is_checked =
      user.is_checked !== null && user.is_checked !== undefined
        ? user.is_checked
        : true;
    
        this.permissions = user.permissions ? user.permissions : [];
  }

  public getPermissions(): string[] {
    return this.permissions;
  }
}
