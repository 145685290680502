import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertService } from 'src/app/auth/services/alert/alert.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  public endPoint: string = 'document';

  constructor(private http: HttpClient, private alertService: AlertService) {}

  public show(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  public details(document_id: number): Observable<any> {
    return this.http
      .get(`${environment.base_api_url_gesres}${this.endPoint}/${document_id}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public delete(document_id: number): Observable<any> {
    return this.http
      .delete(
        `${environment.base_api_url_gesres}${this.endPoint}/${document_id}`
      )
      .pipe(
        map((response: any) => {
          this.alertService.setSuccess(response.msg);
          return response;
        })
      );
  }
}
