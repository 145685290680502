import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReloadListDiService {
  private _reload = new Subject<boolean>();
  public reload = this._reload.asObservable();

  constructor() { }

  public onReload(change: boolean): void {
    this._reload.next(change);
  }
}
