import { IDi } from '../interfaces/di.interface';

export class MDI implements IDi {
  public id: number;
  public transport_id: number;

  public di_internal: string;
  public di_external: string;
  public state_action: string | null;
  public waste_management_center_name: string | null;
  public waste_management_center_address: string | null;
  public constructor_site_name: string | null;
  public constructor_site_address: string | null;
  public transport_state_action: string | null;
  public date_relocation_init: string | null;
  public date_delivered: string | null;

  public waste_center_cp: string | null;
  public waste_center_province: string | null;
  public waste_center_town: string | null;
  public waste_management_center_cp: string | null;
  public waste_management_center_province: string | null;
  public waste_management_center_town: string | null;

  public constructor_site_cp: string | null;
  public constructor_site_province: string | null;
  public constructor_site_town: string | null;

  public waste_center_name: string | null;
  public waste_center_address: string | null;

  public ler_id: number | null;
  public ler_code: string | null;
  public ler_description: string | null;
  public ler_quantity_received: number | null;

  public quantity_received: number | null;
  public license_plate: string | null;
  public transport_quantity_received: number | null;
  public transport_received_date: string | null;

  public di_document_path: string | null;

  constructor(di: IDi) {
    this.id = di.id ? di.id : 0;
    this.transport_id = di.transport_id ? di.transport_id : 0;
    
    this.di_internal = di.di_internal ? di.di_internal : '';
    this.di_external = di.di_external ? di.di_external : '';
    this.state_action = di.state_action ? di.state_action : null;
    
    this.waste_management_center_name = di.waste_management_center_name
      ? di.waste_management_center_name
      : null;
    this.waste_management_center_address = di.waste_management_center_address
      ? di.waste_management_center_address
      : null;
    this.constructor_site_name = di.constructor_site_name
      ? di.constructor_site_name
      : null;
    this.constructor_site_address = di.constructor_site_address
      ? di.constructor_site_address
      : null;
    this.transport_state_action = di.transport_state_action
      ? di.transport_state_action
      : null;
    this.date_relocation_init = di.date_relocation_init
      ? di.date_relocation_init
      : null;
    this.date_delivered = di.date_delivered ? di.date_delivered : null;

    this.waste_center_cp = di.waste_center_cp ? di.waste_center_cp : null;
    this.waste_center_province = di.waste_center_province
      ? di.waste_center_province
      : null;
    this.waste_center_town = di.waste_center_town ? di.waste_center_town : null;
    this.constructor_site_cp = di.constructor_site_cp
      ? di.constructor_site_cp
      : null;
    this.constructor_site_province = di.constructor_site_province
      ? di.constructor_site_province
      : null;
    this.constructor_site_town = di.constructor_site_town
      ? di.constructor_site_town
      : null;

    this.waste_center_name = di.waste_center_name ? di.waste_center_name : null;
    this.waste_center_address = di.waste_center_address ? di.waste_center_address : null;

    this.ler_id = di.ler_id ? di.ler_id : null;
    this.ler_code = di.ler_code ? di.ler_code : null;
    this.ler_description = di.ler_description ? di.ler_description : null;
    this.ler_quantity_received = di.ler_quantity_received ? di.ler_quantity_received : null;

    this.waste_management_center_cp  = di.waste_management_center_cp ? di.waste_management_center_cp : null;
    this.waste_management_center_province = di.waste_management_center_province ? di.waste_management_center_province : null;
    this.waste_management_center_town = di.waste_management_center_town ? di.waste_management_center_town : null;

    this.quantity_received = di.quantity_received ? di.quantity_received : null;
    this.license_plate = di.license_plate ? di.license_plate : null;
    this.transport_quantity_received = di.transport_quantity_received ? di.transport_quantity_received : null;
    this.transport_received_date = di.transport_received_date ? di.transport_received_date : null;

    this.di_document_path = di.di_document_path ? di.di_document_path : null;
  }
}
