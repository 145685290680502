import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MessagingService } from 'src/app/services/messaging.service';

@Component({
  selector: 'gesres-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  // public logo: string = '/assets/img/LogoGESRES.jpg';
  public logo = '/assets/img/logo5.png';
  public form!: FormGroup;
  public loading: boolean = false;
  public recover_loading: boolean = false;
  public message: any;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private messagingService: MessagingService
  ) {}

  public ngOnInit(): void {
    this.ngBuildForm();
  }

  public onSubmit(event: Event) {
    event.preventDefault();

    if (this.form.valid) {
      this.loading = true;

      this.authService.login(this.form.value).subscribe(
        (resp) => {
          this.loading = false;

          this.messagingService.requestPermission();
          this.messagingService.receiveMessage();
          this.message = this.messagingService.currentMessage;

          //this.router.navigate(['/manager'], { relativeTo: this.route });
        },
        (err: HttpErrorResponse) => {
          this.loading = false;
        }
      );
    } else {
      this.form.markAllAsTouched();
    }
  }

  public recoverPassword() {
    if (!this.email.errors) {
      this.recover_loading = true;

       this.authService.restore(this.form.value.email).subscribe(
        (resp: any) => {
          this.recover_loading = false;
        },
        (err: HttpErrorResponse) => {
          this.recover_loading = false;
        }
      );
    } else {
      this.form.get('email')?.markAsTouched();
    }
  }

  private ngBuildForm(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    this.form.reset();
  }

  get email() {
    return this.form?.get('email')!;
  }
  get password() {
    return this.form?.get('password')!;
  }
}
