import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../alert/alert.service';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUser } from '../../interfaces';
import { MUser } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user?: MUser;

  private endPoint: string = 'auth/';
  private drop_token_check: boolean = false;

  get token(): string {
    if (!this.drop_token_check) {
      this.checkApiAcessToken();
    }

    return localStorage.getItem('gesres-app-token') || '';
  }

  get header(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${this.token}`,
    });
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private alertService: AlertService
  ) {
    this.user = new MUser(this.getSession());
  }

  public login(user: { email: string; password: string }): Observable<boolean> {
    return this.http
      .post(`${environment.base_api_url_gesres}${this.endPoint}login`, user)
      .pipe(
        map((response: any) => {
          this.saveToken(response.access_token);

          const user: IUser = {
            ...response.user,
            enterprise: response.enterprise,
            roles: response.roles,
            permissions: response.permissions,
          };

          this.user = new MUser(user);

          this.openSession(this.user);
          return true;
        })
      );
  }

  public logout(): Observable<boolean> {
    this.alertService.showLoading();

    return this.http
      .get(`${environment.base_api_url_gesres}${this.endPoint}logout`, {
        headers: this.header,
      })
      .pipe(
        map((resp: any) => {
          /*  this.filterCookies.ngClearAllFilters(); */
          this.closeSession();
          this.alertService.hideLoading();
          return true;
        })
      );
  }

  public refresh(): Observable<boolean> {
    return this.http
      .get(`${environment.base_api_url_gesres}${this.endPoint}refresh`, {
        headers: this.header,
      })
      .pipe(
        map((resp: any) => {
          this.saveToken(resp.access_token);

          const user = this.getSession();
          this.user = new MUser(user);

          return true;
        })
      );
  }

  public me(): Observable<IUser> {
    return this.http
      .get(`${environment.base_api_url_gesres}${this.endPoint}me`, {
        headers: this.header,
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public getSession(): IUser {
    let localUser = localStorage.getItem('user-app-gesres');

    if (!localUser) {
      return <IUser>{};
    }

    return JSON.parse(localUser);
  }

  public openSession(user: MUser) {
    var last_session = this.getSession();

    if(last_session.email !== user.email && localStorage.getItem('gesres-notification-app-token') !== null){
      localStorage.removeItem('gesres-notification-app-token');
    }

    localStorage.setItem('user-app-gesres', JSON.stringify(user));
  }

  public closeSession(auto: boolean = false) {
    //console.log("closeSession: ", auto);
    if (auto) auto = this.token !== '';

    this.deleteToken();
    this.router.navigateByUrl(auto ? '/expired' : '/login');
  }

  public hasPermission(permissions: string[]): boolean {
    if (!permissions.length) return true;

    return !permissions.some(
      (permission: string) =>
        this.user!.getPermissions().indexOf(permission) == -1
    );
  }

  public checkToken(): boolean {
    return localStorage.getItem('gesres-app-token') !== null;
  }

  public restore(email: string): Observable<void> {
    return this.http
      .get(
        `${environment.base_api_url_gesres}${this.endPoint}password/restore/${email}`
      )
      .pipe(
        map((resp: any) => {
          this.alertService.setSuccess(resp.msg, true);
        })
      );
  }

  private saveToken(token: string): void {
    localStorage.setItem('gesres-app-token', token);
  }

  private deleteToken(): void {
    localStorage.removeItem('gesres-app-token');
    localStorage.removeItem('gesres-notification-app-token');
  }

  private checkApiAcessToken() {
    const element = <HTMLInputElement>(
      document.getElementById('api_access_token')
    );

    if (element !== null && element.value !== '') {
      const token = element.value;
      this.saveToken(token);

      element.value = '';
      element.remove;
    }
  }
}
