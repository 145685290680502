import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert/alert.service';
import { AlertModel } from '../../models';

@Component({
  selector: 'gesres-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  public alerts: AlertModel[];

  constructor(public alertSevice: AlertService) { 
    this.alerts = [];
  }

  ngOnInit(): void {
    this.alerts = this.alertSevice.alert;
  }

  ngDoCheck(): void {
    this.alerts = this.alertSevice.alert;
  }

  /*Public functions***************************************************/
  public close(id: number) {
    this.alertSevice.destroyAlert(id);
  }
}
