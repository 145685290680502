export enum ALERT_CODE {
    CODE_SUCCESS = 1,
    CODE_WARNING = 2,
    CODE_ERROR = 3
}
export class AlertModel{
    readonly CODE_SUCCESS: number = 1;
    readonly CODE_WARNING: number = 2;
    readonly CODE_ERROR: number   = 3;

    constructor(
        private id: number,
        private msg: string = '',
        private code: number = 1,
        private visibility: boolean = false
    ){}

    /*Public funtions*****************************************/
    public setAlert(code: number, msg: string, visibility = false){
        this.code       = code;
        this.msg        = msg;
        this.visibility = visibility;

       // this.programCloseVisibility();
    }

    public setVisibility(isShow: boolean){
        this.visibility = isShow;

        this.programCloseVisibility();
    }

    public hidden(){
        this.visibility = false;
    }
   
    public getCode(): number{
        return this.code;
    }

    public setCode(code: number){
        this.code = code;
    }

    public getMsg(): string{
        return this.msg;
    }

    public getId(): number {
        return this.id;
    }

    public setMsg(msg: string){
        this.msg = msg;
    }

    public isVisibility(){
        return this.visibility;
    }

    public isSuccess(){
        return this.code === this.CODE_SUCCESS;
    }

    public isWarning(){
        return this.code === this.CODE_WARNING;
    }

    public isError(): boolean{
        return this.code == this.CODE_ERROR;
    }

    private programCloseVisibility(){
        if(!this.isVisibility()){
            return;
        }
            
        var ref = this;

        setTimeout(function(){
            ref.hidden();
            
        }, 5000);
    }
}