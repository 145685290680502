export const environment = {
  production: false,
  base_api_url: 'https://gesres.docker.dev.rlecloud.es/api/',
  base_api_url_gesres: 'https://gesres.docker.dev.rlecloud.es/api/platform/',
  base_api_url_app: 'https://gesres.docker.dev.rlecloud.es/api/app/',
  base_public_url: 'https://gesres.docker.dev.rlecloud.es/',

  i18n_url: 'assets/i18n/',
  base_api_local: './assets/data/',
  firebase: {
    apiKey: "AIzaSyCdX0U2nu8Erp2pfCXb_0TEbgOiA4h6Iz4",
    authDomain: "gesres-rle-app-a7881.firebaseapp.com",
    projectId: "gesres-rle-app-a7881",
    storageBucket: "gesres-rle-app-a7881.appspot.com",
    messagingSenderId: "346861710562",
    appId: "1:346861710562:web:e9df77364ded094a8002bb",
    measurementId: "G-6VQQVPTDB5"
  }
};
