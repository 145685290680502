import { RouterModule, Routes } from '@angular/router';
import { DiDetailsComponent, DiListComponent } from './views';
import { NgModule, inject } from '@angular/core';
import { RoutingService } from './services/routing.service';

const routes: Routes = [
  {
    path: '',
    component: DiListComponent,
    children: [
      {
        path: ':id',
        component: DiDetailsComponent,
        resolve: {
          onBack: () => {
            const routingService = inject(RoutingService);

            routingService.ngShowRoutingBack();
          },
        },
      },
    ],
    resolve: {
      onBack: () => {
        const routingService = inject(RoutingService);

        routingService.ngHiddenRoutingBack();
      },
    },
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManagerRoutingModule {}
