import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value:string): string {
    if(value === null || value === undefined){
      return value;
    }
    
    let first = value.substr(0,1).toUpperCase();

    if(first === '¿' || first === '¡'){
      let second = value.substr(1,1).toUpperCase();

      return first + second + value.substr(2); 
    }
    
    return first + value.substr(1); 
  }
}
