import { Component, OnInit } from '@angular/core';
import { MessagingService } from './services/messaging.service';
import { AuthService } from './auth/services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'gesres-app';

  constructor(
    private authService: AuthService,
    private messagingService: MessagingService
  ) {}

  ngOnInit() {
    if (this.authService.checkToken()) {
      this.messagingService.requestPermission();
      this.messagingService.receiveMessage();
    }
  }
}
