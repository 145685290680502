<div mat-dialog style="width: 100% !important">
  <div class="row">
    <div class="modal-content">
      <!-- Header -->
      <div
        class="modal-header d-flex align-items-center col border-bottom mb-2"
      >
        <h2>{{ "Rechazo Di" | capitalize }}</h2>
        <button
          type="button"
          (click)="ngClose()"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <!-- Body -->
      <form
        [formGroup]="form"
        novalidate
        class="form-material"
        (ngSubmit)="onSubmit($event)"
      >
        <!-- Body Form -->
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group non-overflow">
                <label for="description" class="mb-0">{{
                  "Description"
                }}</label>
                <input
                  id="description"
                  formControlName="description"
                  type="text"
                  [maxlength]="maxDescriptionLength"
                  [ngClass]="description.errors?.['required'] && description.touched ? 'form-control form-control-line input-required' : 'form-control form-control-line'"
                />
                <small
                  *ngIf="description.errors?.['required'] && description.touched"
                  class="text-danger"
                >
                  {{ "Campo Requerido" }}
                </small>
                <div class="char-counter">
                  {{ description.value?.length || 0 }}/{{
                    maxDescriptionLength
                  }}
                  caracteres
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div class="modal-footer justify-content-between">
          <div class="justify-content-start">
            <div *ngIf="!loading">
              <div *ngIf="msg_error" class="text-danger ms-3">
                <span>{{ msg_error }}</span>
              </div>
              <div *ngIf="!msg_error" class="text-danger ms-3">
                <span></span>
              </div>
            </div>
            <button
              *ngIf="loading"
              class="btn btn-light"
              type="button"
              disabled=""
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              {{ "cargando datos..." }}
            </button>
          </div>
          <div class="actions justify-content-center" *ngIf="!loading">
            <button
              type="button"
              class="btn btn-danger font-weight-medium waves-effect me-2"
              (click)="ngClose()"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i
                class="fas fa-reply feather feather-send feather-sm fill-white me-2"
              ></i>
              <span>{{ "Cerrar" }}</span>
            </button>
            <button
              type="submit"
              class="btn btn-info font-weight-medium waves-effect me-2"
              data-bs-dismiss="modal"
            >
              <i
                class="fa fa-paper-plane feather feather-send feather-sm fill-white me-2"
              ></i>
              <span>{{ "Enviar" }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
