import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authServices: AuthService, private router: Router, private route: ActivatedRoute) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    
    /* Check exist token */
    if (!this.authServices.checkToken()) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    return this.authServices.refresh().pipe(
      tap( isAuth => {
        if (!isAuth) {
          this.authServices.closeSession(true);
        }

        /* Comprobamos si tenemos los permisos para entrar a ver una vista */
       /*  if (!this.authServices.hasPermission(next.data['permissions'])) {
          this.router.navigateByUrl('/unauthorized');
          return false;
        } */

        return true;
      })
    );
  }
}
