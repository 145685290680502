import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertService } from '../auth/services/alert/alert.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ReloadService } from './reload.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  public currentMessage = new BehaviorSubject<any>(null);
  private _tokenStorage: string = "gesres-notification-app-token";

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient,
    private alertService: AlertService,
    private _snackBar: MatSnackBar,
    private reloadService: ReloadService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  public requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token: any) => {

        if (!localStorage.getItem(this._tokenStorage)) {
          this.notificationAPI(token).subscribe((response: any) => {
            this.alertService.setSuccess(response.msg);

            localStorage.setItem(this._tokenStorage, JSON.stringify(token));

            this.router.navigate(['/manager'], { relativeTo: this.route });

          }, (error) => { console.log(error); })

        }else{
          this.router.navigate(['/manager'], { relativeTo: this.route });
        }
      },
      (error: any) => {
        console.error('Unable to get permission to notify.', error);
      }
    );
  }

  public receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      console.log('New notification received: ', payload);
      this.currentMessage.next(payload);

      if (payload.notification.click_action) {
        this.handleNotification(payload.notification);
      } else {
        // Si no hay una acción de clic específica, muestra la Snackbar como antes
        if (this.router.url === '/manager') {
          this.openSnackBar(payload.notification.title, "Recargar");
        } else {
          this.openSnackBar(payload.notification.title);
        }
      }
    });
  }

  public notificationAPI(token_device_key: string): Observable<any> {
    return this.http
      .post(`${environment.base_api_url_gesres}notification`, {
        token_device_key,
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  private openSnackBar(message: string, action: string = "") {
    let config = new MatSnackBarConfig();
    config.duration = 3000;

    let snackBarRef = this._snackBar.open(message, action? action : "", config);

    snackBarRef.onAction().subscribe(() => {
      //console.log("onAction");
      this.reloadService.reloadComponent();
    });
  }

  private handleNotification(notificationData: any) {
    // Aquí puedes verificar el contenido de la notificación y decidir qué hacer
    if (notificationData.click_action) {
      // Por ejemplo, si la notificación contiene una acción de clic para navegar
      this.router.navigate([notificationData.click_action]);
    } else {
      // Otras acciones, como mostrar un mensaje en la aplicación
      this.openSnackBar(notificationData.title);
    }
  }
}
