import { style } from "@angular/animations";

export const slideOutLeft = [
    style({ transform: 'translate3d(0, 0, 0) scaleX(0.9)', offset: .2}),
    style({ transform: 'translate3d(-2000px, 0, 0) scaleX(2)', offset: 1})
];

export const slideOutRight = [
    style({ transform: 'translate3d(0, 0, 0) scaleX(0.9)', offset: .2}),
    style({ transform: 'translate3d(2000px, 0, 0) scaleX(2)', offset: 1})
];