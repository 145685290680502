import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDi, IDiTransport, IFilterDIList } from '../interfaces';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/auth/services/alert/alert.service';
import { MDI } from '../models/di.model';

@Injectable({
  providedIn: 'root',
})
export class TransportService {
  public endPoint: string = 'transport';

  constructor(private http: HttpClient, private alertService: AlertService) {}

  public list(filter: IFilterDIList, offset: number = 0, limit: number = 100): Observable<IDi[]> {
    return this.http
      .get(`${environment.base_api_url_app}${this.endPoint}/list/${offset}/${limit}`)
      .pipe(
        map((response: any) => {
          if (!response.dis) return [];

          return response.dis.map((di: IDi) => new MDI(di));
        })
      );
  }

  public details(id: number): Observable<IDi> {
    return this.http
      .get(`${environment.base_api_url_app}${this.endPoint}/${id}`)
      .pipe(
        map((response: any) => {
          return new MDI(response.di);
        })
      );
  }

  public update(id: number, transport: IDiTransport): Observable<IDi> {
    console.log("transport:",transport);
    return this.http
      .put(`${environment.base_api_url_app}${this.endPoint}/${id}`, {
        transport,
      })
      .pipe(
        map((response: any) => {
          this.alertService.setSuccess(response.msg);

          return new MDI(response.transport);
        })
      );
  }
}
