import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-accept-di-modal',
  templateUrl: './accept-di-modal.component.html',
  styleUrls: ['./accept-di-modal.component.css'],
})
export class AcceptDiModalComponent implements OnInit {
  public form!: FormGroup;
  public loading: boolean = false;
  public msg_error: string | boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AcceptDiModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public ngOnInit(): void {
    this.ngBuildForm();
    this.ngInit();
  }

  public ngBuildForm(): void {
    this.form = this.fb.group({
      quantity_received: [
        '',
        [Validators.required, Validators.pattern('^\\d*\\.?\\d?$')],
      ],
      license_plate: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(12),
        ],
      ],
    });

    if (this.data) {
      const quantityInTons = (this.data.ler_quantity_received / 1000).toFixed(
        1
      );
      this.form.patchValue({
        quantity_received: quantityInTons,
        license_plate: this.data.license_plate,
      });
    }
  }

  public formatInput(event: any): void {
    let value = event.target.value;

    let formatted = value.replace(/,/g, '.').replace(/[^0-9.]/g, '');

    formatted = formatted
      .replace(/\.(?=.*\.)/g, '')
      .replace(/(\.\d{1}).*$/, '$1');

    this.form
      .get('quantity_received')!
      .setValue(formatted, { emitEvent: false });
  }

  public onSubmit(e: any): void {
    e.preventDefault();

    if (this.form.valid) {
      this.loading = true;
      const quantityInKg = Number(this.quantity_received.value) * 1000;
      this.dialogRef.close({
        quantity_received: quantityInKg,
        license_plate: this.license_plate.value,
      });
      this.loading = false;
    } else {
      this.form.markAllAsTouched();
    }
  }

  public ngClose(): void {
    this.dialogRef.close();
  }

  private ngInit(): void {}

  get quantity_received() {
    return this.form.get('quantity_received')!;
  }

  get license_plate() {
    return this.form.get('license_plate')!;
  }
}
