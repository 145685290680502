<div class="main-wrapper">
  <div
    class="auth-wrapper d-flex no-block align-items-center"
  >
    <!-- START LOGO GESRES LOGIN -->
    <div class="row" style="flex-grow: 2;">
      <div
        class="col-12 justify-content-center text-center align-items-center d-flex"
      >
      <img [src]="logo" alt="gesresLogo" class="w-100 mb-2 filterinvert"/>
      </div>
    </div>
    <!-- END LOGO GESRES LOGIN -->

    <div class="auth-box p-3 bg-white rounded">
      <form
        [formGroup]="form"
        novalidate
        class="form-material mt-4"
        (ngSubmit)="onSubmit($event)"
      >
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
            <div class="form-group">
              <label for="email">{{ "correo" | capitalize }}</label>
              <input
                id="email"
                formControlName="email"
                type="text"
                class="form-control form-control-line"
                [ngClass]="email.errors?.['required'] && email.touched ? 'input-required' : 'form-control-line'"
              />
              <small
                *ngIf="email.errors?.['required'] && email.touched"
                class="text-danger"
              >
                {{ "Campo requerido" | capitalize }}
              </small>
              <small *ngIf="email.errors?.['email']" class="text-danger">
                {{ "Correo incorrecto" | capitalize }}
              </small>
            </div>
          </div>
          <div class="form-group">
            <label for="password">{{ "password" | capitalize }}</label>
            <input
              id="password"
              formControlName="password"
              type="password"
              class="form-control form-control-line"
              [ngClass]="password.errors?.['required'] && password.touched ? 'input-required' : 'form-control-line'"
            />
            <small
              *ngIf="password.errors?.['required'] && password.touched"
              class="text-danger"
            >
              {{ "Campo requerido" | capitalize }}
            </small>
          </div>
          <div class="form-group text-center mt-4 mb-3">
            <div class="col-xs-12">
              <span
                *ngIf="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              >
              </span>
              <button
                *ngIf="!loading"
                class="btn btn-info w-100 waves-effect waves-light rounded border-0 p-2 fs-5"
                type="submit"
              >
                {{ "acceder" | uppercase }}
              </button>
            </div>
          </div>
          <hr />
          <div class="ms-auto">
            <span
              *ngIf="recover_loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            >
            </span>
            <a
              *ngIf="!recover_loading"
              (click)="recoverPassword()"
              id="to-recover"
              class="link font-weight-medium d-flex align-items-center pointer"
            >
              <i class="ri-lock-line fs-5 me-1"></i>
              {{ "Recuperar contraseña" | capitalize }}</a
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
