import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AlertService } from '../auth/services/alert/alert.service';
import { AuthService } from '../auth/services/auth/auth.service';

import { Observable, delay, of, throwError } from 'rxjs';
import { catchError, concatMap, retryWhen } from 'rxjs/operators';

import * as moment from 'moment';

const HTTP_CODE_SESSION_EXPIRED = 419;

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  private retryCount: number;
  private delay: number;

  constructor(
    private alertService: AlertService,
    private authServices: AuthService
  ) {
    this.retryCount = 5;
    this.delay = 500;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers = this.authServices.header;

    const reqClone = req.clone({ headers: headers });

    return next.handle(reqClone).pipe(
      retryWhen((error) => this.retryRequest(error, this.retryCount)),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = `Error Code: ${err.status}\nMessage: ${err.statusText}`;

        if (err.error instanceof ErrorEvent) {
          // client-side error
          this.alertService.setError(err.error.error, true);
        } else {
          // server-side error
          this.alertService.setError(err.error.error, true);

          if (err.status) {
            if (err.status === HttpStatusCode.Unauthorized) {
              /* this.authServices.unauthorized(); */
            }

            if (err.status === HTTP_CODE_SESSION_EXPIRED) {
              /* We check if the token was created yesterday */
              this.authServices.closeSession(
                this.tokenDayExpired(localStorage.getItem('gesres-app-token')!)
              );
            }
          }
        }

        this.alertService.hideLoading();
        return throwError(err);
      })
    );
  }

  private tokenDayExpired(token: string) {
    const expiry = moment.unix(JSON.parse(atob(token.split('.')[1])).iat);
    return expiry.isSame(moment(Date.now()), 'day');
  }

  retryRequest(
    error: Observable<HttpErrorResponse>,
    retryCount: number
  ): Observable<unknown> {
    return error.pipe(
      delay(this.delay), // 5ms
      concatMap((checkErr: HttpErrorResponse, count: number) => {
        console.log(`Error code: ${checkErr.status} - count: ${count}`);

        if (count <= retryCount && this.isRepeatRequest(checkErr.status)) {
          return of(checkErr);
        }

        return throwError(checkErr);
      })
    );
  }

  isRepeatRequest(statusCode: number): boolean {
    return (
      statusCode === HttpStatusCode.TooManyRequests,
      statusCode === HttpStatusCode.RequestTimeout,
      statusCode === HttpStatusCode.GatewayTimeout,
      statusCode == 0
    );
  }
}
