import { Injectable } from '@angular/core';
import { AlertModel, ALERT_CODE } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private id: number;

  public alert: AlertModel[];
  public loading: boolean;
  public lastIdEvent: string = "";

  constructor() {
    this.alert = [];
    this.loading = false;
    this.id = 0;
  }

  /*   public clearMsgServer(){
    this.alert.setCode(1);
    this.alert.setMsg('');
  } */

  public setResponse(code: number, msg: string, showMsg: boolean = false) {
    const tmpAlert = new AlertModel(
      this.id++,
      msg,
      ALERT_CODE.CODE_SUCCESS,
      showMsg
    );

    this.alert.unshift(tmpAlert);

    this.destroyAlertTemp(tmpAlert.getId());
  }

  public setSuccess(msg: string, showMsg: boolean = true) {
    const tmpAlert = new AlertModel(
      this.id++,
      msg,
      ALERT_CODE.CODE_SUCCESS,
      showMsg
    );

    this.alert.unshift(tmpAlert);

    this.destroyAlertTemp(tmpAlert.getId());
  }

  public setError(error: any, showMsg: boolean = false) {
    const tmpAlert = new AlertModel(
      this.id++,
      error,
      ALERT_CODE.CODE_ERROR,
      showMsg
    );

    this.alert.unshift(tmpAlert);

    this.destroyAlertTemp(tmpAlert.getId());
  }

  public showLoading() {
    this.loading = true;
  }

  public hideLoading() {
    this.loading = false;
  }

  public destroyAlertTemp(id: number) {
    setTimeout(() => {
      this.destroyAlert(id);
    }, 2500);
  }

  public destroyAlert(id: number){
    this.alert = this.alert.filter((a: AlertModel) => {
      return a.getId() !== id;
    });
  }
}
