import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'state'
})
export class StatePipe implements PipeTransform {
  readonly state: { [key: string]: string } = {
    en_transito: "En Transito",
    aceptada: "Aceptada",
    rechazada: "Rechazada",
    pendiente: "Pendiente",
    entregada: "Entregada"
  };

  transform(value: string): unknown {
    return this.state[value];
  }
}
