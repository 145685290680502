<h1 mat-dialog-title>¿Estás seguro?</h1>
<div mat-dialog-actions>
  <div class="row">
    <div class="col-6 p-0">
      <button
        type="button"
        class="btn btn-danger font-weight-medium px-4 w-100 rounded-0"
        (click)="onClick(false)"
      >
        <div class="d-flex align-items-center justify-content-center">
          <i
            class="fas fa-times feather feather-send feather-sm fill-white me-2"
          ></i>
          {{ "Cancelar" }}
        </div>
      </button>
    </div>
    <div class="col-6 p-0">
      <button
        type="button"
        class="btn btn-info font-weight-medium px-4 w-100 rounded-0"
        (click)="onClick(true)"
      >
        <div class="d-flex align-items-center justify-content-center">
          <i
            class="fa fa-paper-plane feather feather-send feather-sm fill-white me-2"
          ></i>
          {{ "Enviar" }}
        </div>
      </button>
    </div>
  </div>
</div>
