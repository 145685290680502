<div class="main-wrapper">
    <div class="text-center">
      <div class="card text-center bg-white container">
        <div class="card-body">
          <h3 class="text-uppercase card-text mt-3">{{ 'Session expirada' }}</h3>
          <h1 class="text-uppercase card-text mt-5 errorcode">440</h1>
          <h2 class="text-uppercase card-text error-message mb-4">
            Session expired
          </h2>
          <a
            [routerLink]="['../login']"
            class="btn btn-info btn-rounded waves-effect waves-light mb-5 mt-5 back-button"
            >{{ 'Login' | capitalize }}
          </a>
        </div>
      </div>
    </div>
  </div>
  