import { Component } from '@angular/core';
import { AlertService } from '../../services/alert/alert.service';

@Component({
  selector: 'gesres-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent {

  constructor(private alertService: AlertService) { }

  public isLoading(): boolean {
    return this.alertService.loading;
  }
}
