import { RouterModule, Routes } from "@angular/router";
import { ExpiredComponent, LoginComponent, NotfoundComponent } from "./auth/views";
import { NgModule } from "@angular/core";
import { MainComponent } from "./manager/views";
import { AuthGuard } from "./auth/guard/guard.service";
import { ManagerRoutingModule } from "./manager/manager.routing";

const routes: Routes = [
    {
        path: '',
        pathMatch:"full",
        redirectTo: "manager",
    },
    {
        path: '',
        canActivate: [AuthGuard],
        component: MainComponent,
        children: [
            {
                path: "manager",
                loadChildren: () => ManagerRoutingModule
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'expired',
        component: ExpiredComponent
    },
    {
        path: '**',
        component: NotfoundComponent
    }
]
@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}