import { Component, OnInit } from '@angular/core';
import { IFilterDIList, IDi, IDiTransport } from '../../interfaces';
import { TransportService } from '../../services/transport.service';
import { DiAction } from '../../config';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/auth/services/alert/alert.service';
import * as kf from '../../config/index';
import {
  DialogConfirmComponent,
} from '../../components/index';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ReloadListDiService } from '../../shared/reaload-list-di/reload-list-di.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { AcceptDiModalComponent } from '../../modals/accept-di/accept-di-modal.component';
import { DeclineDiModalComponent } from '../../modals/decline-di/decline-di-modal.component';

@Component({
  selector: 'gesres-di-list',
  templateUrl: './di-list.component.html',
  styleUrls: ['./di-list.component.css'],
})
export class DiListComponent implements OnInit {
  public diList: IDi[] = [];
  public animationState: string[] = [];
  public durationInSeconds = 2;
  public di_state_badge = kf.DI_STATE_BADGE;
  constructor(
    public dialog: MatDialog,
    private transportService: TransportService,
    private router: Router,
    public route: ActivatedRoute,
    private alertService: AlertService,
    private reloadListDi: ReloadListDiService,
    private messagingService: MessagingService,
  ) {}

  public ngOnInit(): void {
    this.ngInitFirebase();
    this.reloadListDi.reload.subscribe((response) => {
      this.ngList();
    });
    this.ngList();
  }

  private ngInitFirebase() {
    this.messagingService.receiveMessage();
  }

  public ngList(): void {
    const filter: IFilterDIList = { rcd: null, transport: null };
    this.alertService.showLoading();
    this.transportService.list(filter).subscribe(
      (diList: IDi[]) => {
        this.diList = diList;
        this.alertService.hideLoading();
      },
      (error) => {
        this.alertService.hideLoading();
      }
    );
  }

  public onSelect(e: any, action: DiAction, item: IDi): void {
    e.stopPropagation();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = item;
    dialogConfig.width = '98%';
    // dialogConfig.height = '95%';

    let dialogRef: MatDialogRef<AcceptDiModalComponent, any> | MatDialogRef<DeclineDiModalComponent, any> | undefined;

    if (action === 'aceptada') {
      dialogRef = this.dialog.open(AcceptDiModalComponent, dialogConfig);
    } else if (action === 'rechazada') {
      dialogRef = this.dialog.open(DeclineDiModalComponent, dialogConfig);
    }

    if (dialogRef) {
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.updateTransportState(item, action, result.description, result.quantity_received, result.license_plate);
        }
      });
    }
  }


  private updateTransportState(
    item: IDi,
    action: DiAction,
    description: string,
    quantity_received: number | null,
    license_plate: string | null
  ): void {
    let state_action = action === 'aceptada' ? 'aceptada' : 'rechazada';

    const transport: IDiTransport = {
      state_action: state_action,
      description: description,
      quantity_received: quantity_received,
      license_plate: license_plate,
      device_id: null,
    };

    this.alertService.showLoading();

    this.transportService.update(item.transport_id, transport).subscribe(
      (response: IDi) => {
        this.ngList();
        this.alertService.hideLoading();
      },
      (error) => {
        this.alertService.hideLoading();
      }
    );
  }

  public onClick(data: IDi): void {
    if (data.transport_state_action !== 'rechazada')
      this.router.navigate(['./', data.transport_id], {
        relativeTo: this.route,
      });
  }

  public resetAnimation(index: number) {
    if (this.animationState.length) this.animationState[index] = '';
  }

  public onReload(e: any): void {
    this.ngList();
  }

}
