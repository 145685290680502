import { IBase } from "../../interfaces";

export class MBase implements IBase {
    constructor(
        public id: number,
        public is_delete: boolean,
        public is_active: boolean,
        public created_at: string,
        public updated_at: string,
        public user_create_id: number,
        public user_created_name: string
      ) {}
}