import { Component, OnInit } from '@angular/core';
import { RoutingService } from '../../services/routing.service';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'gesres-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor(public routingService: RoutingService, private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

  public ngOnInit(): void {
   /*  this.router.navigate(['./manager'], { relativeTo: this.route }); */
  }

  public ngHasRouterChildren(): boolean {
    return this.routingService.onBack;
  }

  public onLogout(): void {
    this.authService.logout().subscribe((resp) => {});
  }
}
