export const DI_TRANSPORT_STATE = {
    ACEPTADA: "aceptada",
    CANCELADA: "rechazada"
}

export const DI_ACTION: { [key: string]: string } = {
    aceptada: "aceptada",
    rechazada: "rechazada",
}

export type DiAction = "aceptada" | "rechazada";

export const DI_STATE_BADGE: { [key: string]: string } = {
    rechazada: "bck-danger",
    aceptada: "bck-info",
    pendiente: "bck-warning"
}