<div
    aria-live="polite"
    aria-atomic="true"
    class="bg-dark position-relative" style="z-index:9999;">

  <div class="toast-container position-fixed top-1 end-0" 
    id="toastPlacement">

    <ng-container *ngFor="let alert of alerts">

      <div [attr.id]="'alert_' + alert.getId()" *ngIf="alert.isVisibility()" class="toast mb-2 d-flex align-items-center text-white border-0 alert-msg"
        [ngClass]="{'bg-success' : alert.isSuccess(), 'bg-warning': alert.isWarning(),  'bg-danger': alert.isError()}"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-bs-autohide="false">

        <div class="toast-body">
          <div class="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info fill-white feather-sm me-2"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="8"></line></svg>
            {{alert.getMsg()}}
          </div>
        </div>

        <button type="button"
          class="btn-close btn-close-white ms-auto me-2"
          data-bs-dismiss="toast"
          aria-label="Close"
          (click)="close(alert.getId())">
        </button>

      </div>

    </ng-container>
  </div>
</div>

