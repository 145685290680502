<div fxLayout="column" fxFlexFill>
  <mat-toolbar
    class="navbar-color mat-elevation-z8 p-0 gesres-navbar"
    fxLayout="row"
    fxLayoutAlign="start stretch"
    style="z-index: 2"
  >
    <button mat-button [routerLink]="['./manager']" *ngIf="ngHasRouterChildren()">
        <mat-icon class="color-white">arrow_back</mat-icon>
    </button>

    <div style="width: 7%;" *ngIf="!ngHasRouterChildren()"></div>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="15px"
      class="pl-3"
    >
      <span fxHide fxShow.xs class="enterprise-name">GESRES</span>
    </div>

    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon class="color-white">more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="onLogout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>

  </mat-toolbar>

  <mat-drawer-container fxFlex autosize class="background">
    <!--PAGES-->
    <div class="px-2 py-3">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
</div>
