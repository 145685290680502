import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';

import {
  MainComponent,
  DiListComponent,
  DiDetailsComponent,
} from './views/index';
import { NavbarComponent } from './components/index';

import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import * as Hammer from 'hammerjs';
import {
  BrowserModule,
  HammerModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';

import { SnackBarComponent } from './components/snack-bar/snack-bar.component';

import { NgDecimalsDirective } from "../directives/ng-decimals/ng-decimals.directive";
import { DeclineDiModalComponent } from './modals/decline-di/decline-di-modal.component';
import { AcceptDiModalComponent } from './modals/accept-di/accept-di-modal.component';

export class HammerConfig extends HammerGestureConfig {

  override overrides = {swipe: { direction: Hammer.DIRECTION_ALL }}

  override buildHammer(element: HTMLElement) {
    let mc = new Hammer(element, {
      touchAction: "auto",
    });
    return mc;
  }
}

@NgModule({
  declarations: [
    MainComponent,
    NavbarComponent,
    NavbarComponent,
    DiListComponent,
    DiDetailsComponent,
    SnackBarComponent,
    NgDecimalsDirective,
    DeclineDiModalComponent,
    AcceptDiModalComponent
  ],
  exports: [MainComponent],
  imports: [
    HammerModule,
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    PipesModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatMenuModule
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
  ],
})
export class ManagerModule {}
